<template>
  <div class="v-personal-detail">
    <base-layout>
      <ul class="basic-info-list">
        <!-- <li class="basic-info-list__item">
          <p class="basic-info-list__item__label">會員類型</p>
          <p class="basic-info-list__item__data">
            {{ mockData.memberLevel }}
          </p>
        </li>

        <li class="basic-info-list__item">
          <p class="basic-info-list__item__label">下載權限</p>
          <p class="basic-info-list__item__data">{{ mockData.downloadPermission }}</p>
        </li> -->
        <li class="basic-info-list__item">
          <p class="basic-info-list__item__label">已下載數量/可下載數量</p>
          <p class="basic-info-list__item__data">{{ mockData.downloadCount }} / {{ mockData.remainingCount }}</p>
        </li>
        <!-- <li class="basic-info-list__item">
          <p class="basic-info-list__item__label">契約起訖期間</p>
          <p class="basic-info-list__item__data">{{ mockData.enableDateRange }}</p>
        </li> -->
      </ul>
      <divide-line />
      <div class="account-info-wrap">
        <n-form :label-width="80" :model="model" :rules="rules" :size="size" ref="formRef" label-placement="top">
          <n-grid :cols="12" :x-gap="20">
            <n-form-item-gi :span="gridColSetting.email" label="電子信箱" path="email">
              <div class="input-remind-wrap">
                <n-input placeholder="Input" v-model:value="model.phone" />
                <p class="remind-text">電子信箱即為您的登入帳號，如有異動需求請聯繫我們</p>
              </div>
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.name" label="姓名" path="name">
              <n-input placeholder="Input" v-model:value="model.name" />
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.gender" label="稱謂" path="gender">
              <n-radio-group v-model:value="model.gender" name="radiogroup1">
                <n-space>
                  <n-radio value="1">先生</n-radio>
                  <n-radio value="2">小姐</n-radio>
                </n-space>
              </n-radio-group>
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.phone" label="手機" path="phone">
              <n-input placeholder="Input" v-model:value="model.phone" />
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.telephone" label="電話" path="telephone">
              <n-input placeholder="Input" v-model:value="model.telephone" />
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.nationality" label="所在國家" path="nationality">
              <n-select placeholder="Select" :options="generalOptions" v-model:value="model.nationality" multiple />
            </n-form-item-gi>
            <n-form-item-gi :span="gridColSetting.city" label="居住城市/地區" path="city">
              <n-select placeholder="Select" :options="generalOptions" v-model:value="model.nationality" multiple />
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.businessType" label="業務屬性" path="businessType">
              <n-select placeholder="Select" :options="generalOptions" v-model:value="model.businessType" multiple />
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.title" label="職位" path="title">
              <n-input placeholder="Input" v-model:value="model.title" />
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.companyName" label="公司名稱" path="companyName">
              <n-input placeholder="Input" v-model:value="model.companyName" />
            </n-form-item-gi>

            <n-form-item-gi :span="gridColSetting.companyNationality" label="公司國家" path="companyNationality">
              <n-input placeholder="Input" v-model:value="model.companyNationality" />
            </n-form-item-gi>
            <n-form-item-gi :span="gridColSetting.companyCity" label="公司城市/地區" path="companyCity">
              <n-input placeholder="Input" v-model:value="model.companyCity" />
            </n-form-item-gi>
            <n-form-item-gi :span="gridColSetting.companyAddress" label="公司地址" path="companyAddress">
              <n-input placeholder="Input" v-model:value="model.companyAddress" />
            </n-form-item-gi>
          </n-grid>
        </n-form>
      </div>

      <div class="btn-wrap">
        <mi-button>Save</mi-button>
      </div>
    </base-layout>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import {
  NForm, NInput, NFormItemGi, NGrid, NSelect, NSpace, NRadio,
} from 'naive-ui';
import BaseLayout from '@/components/MyProfile/BaseLayout.vue';
import DivideLine from '@/components/MyProfile/DivideLine.vue';
import MiButton from '@/components/Global/MiButton.vue';
import { useSiteStore } from '@/store/site';

const mockData = {
  memberLevel: '正式會員',
  downloadPermission: '每月限額',
  downloadCount: 80,
  remainingCount: 100,
  enableDateRange: '31 Jan 2021-31 Dec 2021',
};

export default defineComponent({
  name: 'PersonalDetails',
  components: {
    BaseLayout,
    DivideLine,
    MiButton,
    NForm,
    NInput,
    NGrid,
    NFormItemGi,
    NSelect,
    NSpace,
    NRadio,
  },
  setup() {
    const siteStore = useSiteStore();

    const gridColSetting = computed(() => {
      if (siteStore.deviceType.includes('portrait')) {
        return {
          email: 6,
          name: 3,
          gender: 3,
          phone: 3,
          telephone: 3,
          nationality: 3,
          city: 3,
          password: 3,
          businessType: 3,
          title: 3,
          companyName: 6,
          companyNationality: 3,
          companyCity: 3,
          companyAddress: 6,
        };
      }

      return {
        email: 12,
        name: 12,
        gender: 12,
        phone: 12,
        telephone: 12,
        nationality: 12,
        city: 12,
        password: 12,
        businessType: 12,
        title: 12,
        companyName: 12,
        companyNationality: 12,
        companyCity: 12,
        companyAddress: 12,
      };
    });

    return {
      gridColSetting,
      mockData,
      size: ref('medium'),

      model: ref({
        nationality: '',
        city: '',
        email: '',
        name: '',
        gender: '',
        phone: '',
        telephone: '',
        businessType: '',
        companyName: '',
        password: '',
        title: '',
        companyNationality: '',
        companyCity: '',
        companyAddress: '',
      }),

      rules: {
        nationality: {
          required: true,
          trigger: ['blur'],
        },
        city: {
          required: true,
          trigger: ['blur'],
        },
        gender: {
          required: true,
        },
        phone: {
          required: true,
          trigger: ['input'],
        },

        telephone: {
          trigger: ['input'],
        },
        businessType: {
          required: true,
          trigger: ['input'],
        },
        email: {
          required: true,
          trigger: ['input'],
        },
        password: {
          required: true,
          trigger: ['input'],
        },
      },
      generalOptions: ['groode', 'veli good', 'emazing', 'lidiculous'].map((v) => ({
        label: v,
        value: v,
      })),
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/form.scss';
.basic-info-list {
  @include flex(flex-start, flex-start, column);

  &__item {
    @include flex();

    & + & {
      margin-top: 20px;
    }

    &__label {
      flex: none;
      width: 105px;

      @include font-style($c-assist3, 16, normal, 0.32px, 24px);
    }

    &__data {
      margin-left: 20px;
      @include font-style($c-black, 16, normal, 0.32px, 24px);
    }
  }
}

.btn-wrap {
  @include flex(center);
  margin-top: 60px;
}

@media screen and (min-width: $tablet) {
  .basic-info-list {
    @include flex(space-between);
    @include padding(30px 0);

    &__item {
      @include flex(flex-start, flex-start, column);
      flex: 1;

      & + & {
        margin-top: 0px;
      }

      &__label {
        @include font-style($c-assist3, 16, normal, 0.32px, 24px);
        width: 100%;
      }

      &__data {
        margin-top: 18px;
        margin-left: 0px;
        @include font-style($c-black, 16, normal, 0.32px, 24px);
      }
    }
  }

  .btn-wrap {
    @include flex(flex-end);
  }
}
</style>
